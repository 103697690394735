.notification-wrapper {
	position: fixed;
	top: 30px;
	left: calc(50% - 150px);
	z-index: 9999;
}
.notification-alert {
	width: 300px;
	border-radius: 5px;
	overflow: hidden;
}
.alert-animation.entrance {
	-webkit-animation: slide-in-elliptic-top-fwd 0.45s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-elliptic-top-fwd 0.45s
		cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
	0% {
		-webkit-transform: translateY(-900px) rotateX(-30deg) scale(0);
		transform: translateY(-900px) rotateX(-30deg) scale(0);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		box-shadow: 0 0px 0px 0px #00000000;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) rotateX(0) scale(1);
		transform: translateY(0) rotateX(0) scale(1);
		-webkit-transform-origin: 50% 1400px;
		transform-origin: 50% 1400px;
		box-shadow: 0 -3px 12px 0px #00000080;
		opacity: 1;
	}
}

@keyframes slide-in-elliptic-top-fwd {
	0% {
		-webkit-transform: translateY(-900px) rotateX(-30deg) scale(0);
		transform: translateY(-900px) rotateX(-30deg) scale(0);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		box-shadow: 0 0px 0px 0px #00000000;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) rotateX(0) scale(1);
		transform: translateY(0) rotateX(0) scale(1);
		-webkit-transform-origin: 50% 1400px;
		transform-origin: 50% 1400px;
		box-shadow: 0 -3px 12px 0px #00000080;
		opacity: 1;
	}
}

.alert-animation.exit {
	-webkit-animation: slide-out-blurred-top 0.45s
		cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
	animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06)
		both;
}

@-webkit-keyframes slide-out-blurred-top {
	0% {
		-webkit-transform: translateY(0) scaleY(1) scaleX(1);
		transform: translateY(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(0);
		filter: blur(0);
		box-shadow: 0 -3px 12px 0px #00000080;
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
		transform: translateY(-1000px) scaleY(2) scaleX(0.2);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		box-shadow: 0 0px 0px 0px #00000000;
		opacity: 0;
	}
}

@keyframes slide-out-blurred-top {
	0% {
		-webkit-transform: translateY(0) scaleY(1) scaleX(1);
		transform: translateY(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(0);
		filter: blur(0);
		box-shadow: 0 -3px 12px 0px #00000080;
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
		transform: translateY(-1000px) scaleY(2) scaleX(0.2);
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		box-shadow: 0 0px 0px 0px #00000000;
		opacity: 0;
	}
}

.geekalert {
	padding: 15px 20px;
	color: 222;
	background: #fff;
	border: 1px solid #ccc;
}
.geekalert.success {
	background: #39a78e;
	border-color: #39937e;
	color: #fff;
}
.geekalert.warning {
	background: #e8a64e;
	border-color: #e19c41;
	color: #fff;
}
.geekalert.info {
	background: #16b8f3;
	border-color: #11ace4;
	color: #fff;
}
.geekalert.error {
	background: #cb464a;
	border-color: #c23d42;
	color: #fff;
}
/* Styles for desktop */
.hideShowBackButton {
	/* Desktop styles */
	display: none;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
	.hideShowBackButton {
		/* Mobile styles */
		display: block;
	}

	.centered {
		width: 100%;
		/* padding: 40px 40px 20px 40px; */
		background-color: white;
		text-align: center;
		border-radius: 10px;
	}
}

@media (min-width: 1024px) {
	.cardWidth {
		width: 1000px;
	}
}
@media (max-width: 1024px) {
	.cardWidth {
		width: 100%;
	}
}
.addtextSize span {
	font-size: 16px;
}
.addtextSize2 span {
	font-size: 12px;
}
.confirmation-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background: rgba(7, 64, 110, 0.678);
	border: 1px solid #ccc;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	z-index: 99999999;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.centered {
	width: fit-content;
	padding: 20px 30px 10px 30px;
	background-color: white;
	text-align: center;
	border-radius: 10px;
}

.confirmation-popup button {
	margin: 0 10px;
}
.confirm_btn_y {
	background-color: #103964;
	padding: 5px 40px 5px 40px;
	border-radius: 5px;
	color: white;
	margin-top: 20px;
}
.confirm_btn_n {
	background-color: #5f83a9;
	padding: 5px 40px 5px 40px;
	border-radius: 5px;
	color: white;
	margin-top: 20px;
}
.react-datepicker__month-select {
	background-color: #216ba5;
	padding: 10px 20px;
	color: white;
	border-radius: 5px;
	margin-top: 10px;
}
.react-datepicker__year-select {
	background-color: #216ba5;
	padding: 10px 20px;
	color: white;
	border-radius: 5px;
}
